import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme  } from '@mui/material/styles';
import Home from './pages/Home';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      tertiary: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      tertiary?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4A2E5E',
    },
    secondary: {
      main: '#004650',
    },
    custom: {
      tertiary: '#9F97CB', // Third primary color
    },
    common: {
      white: '#EFECE5',
      black: '#000000',
    },
  },
  typography: {
    fontFamily: 'Varela Round, sans-serif',
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;