import React, { useState } from 'react';
import { BottomNavigation, Button, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';

const CustomBottomNavigation = () => {
    const [value, setValue] = useState(0);
    const theme = useTheme();

    return (
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            sx={{
                width: '100%',
                height: '100px', // Adjust the height as needed
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 16px', // Add padding on the left and right
                '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    height: 'auto',
                    padding: '16px 0', // Adjust padding for smaller screens
                },
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'center', sm: 'left' }, marginBottom: { xs: 2, sm: 0 } }}>
                    <Typography variant="h6" display="flex" alignItems="center">
                        <EmailIcon sx={{ mr: 1 }} />
                        sydney.boschert@live.com
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: 'center', marginBottom: { xs: 2, sm: 0 } }}>
                    <Typography variant="h6">Bit Blossom Software</Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                    <Button variant="contained" color="primary" size="small">
                        Contact Us
                    </Button>
                </Grid>
            </Grid>
        </BottomNavigation>
    );
};

export default CustomBottomNavigation;