// src/Home.tsx
import React from 'react';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
// import Grow from '@mui/material/Grow';
import CodeIcon from '@mui/icons-material/Code';
import HandshakeIcon from '@mui/icons-material/Handshake';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Box, Typography, Grid  } from '@mui/material';
import { motion } from 'framer-motion';


import { useTheme } from '@mui/material/styles';


const Home: React.FC = () => {
    const theme = useTheme();

    return (
        <>
                  <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc="logo512.png"
                appName="Bit Blossom Software"
            />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                sx={{
                    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    color: theme.palette.common.white,
                    textAlign: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                    <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Blossoming Ideas into Software Solutions
                    </Typography>
                </Slide>
                <Slide direction="down" in={true} timeout={2000} mountOnEnter unmountOnExit>
                    <Typography variant="h5" sx={{ mb: 4 }}>
                        Ready to Turn Your Ideas into Reality?
                    </Typography>
                </Slide>
                <Slide direction="up" in={true} timeout={3000} mountOnEnter unmountOnExit>
                    <Button variant="contained" color="secondary" size="large">
                        Get Started
                    </Button>
                </Slide>
            </Box>
            {/* //Services */}
            <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                background: theme.palette.custom.tertiary,
                color: theme.palette.common.white,
                py: 8,
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>
                Services
            </Typography>

            <Grid container spacing={4} sx={{ px: 4 }}>
                <Grid item xs={12} md={4}>
                    <motion.div whileHover={{ scale: 1.05 }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                m: 2,
                                p: 4,
                                borderRadius: 2,
                                background: 'rgba(255, 255, 255, 0.1)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            <Typography variant="h4" color='secondary' align='center'>
                                Custom Software Development
                            </Typography>
                            <CodeIcon sx={{ fontSize: 100, color: theme.palette.common.white, mt: 2 }} />
                            <Typography variant="subtitle1" gutterBottom color='secondary'>
                                We specialize in crafting custom software that perfectly aligns with your business goals and challenges.
                                Whether you need a completely new application, enhancements to existing systems, or seamless integration with other tools,
                                our solutions are designed to be scalable, efficient, and user-friendly.
                            </Typography>
                        </Box>
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <motion.div whileHover={{ scale: 1.05 }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                m: 2,
                                p: 4,
                                borderRadius: 2,
                                background: 'rgba(255, 255, 255, 0.1)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            <Typography variant="h4" color='secondary'  align='center'>
                                Technology Consulting
                            </Typography>
                            <TipsAndUpdatesIcon sx={{ fontSize: 100, color: theme.palette.common.white, mt: 2 }} />
                            <Typography variant="subtitle1" gutterBottom color='secondary'>
                                We provide strategic advice and hands-on support to ensure your technology choices
                                align with your business objectives and enhance operational efficiency and helping you navigate the ever-changing tech landscape.
                            </Typography>
                        </Box>
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <motion.div whileHover={{ scale: 1.05 }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                m: 2,
                                p: 4,
                                borderRadius: 2,
                                background: 'rgba(255, 255, 255, 0.1)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            <Typography variant="h4" color='secondary'  align='center'>
                                Reliable Support & Maintenance
                            </Typography>
                            <HandshakeIcon sx={{ fontSize: 100, color: theme.palette.common.white, mt: 2 }} />
                            <Typography variant="subtitle1" gutterBottom color='secondary'>
                                We don’t just deliver software and walk away; we’re committed to providing ongoing support and maintenance to
                                ensure your systems continue to operate at their best. From regular updates to troubleshooting and scaling,
                                we’re here to support your long-term success.
                            </Typography>
                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
            <Button variant="contained" color="secondary" sx={{ mt: 4 }}>
                Learn more about our services
            </Button>
        </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ backgroundColor: theme.palette.common.white }}
            >
                <Typography variant="h2" sx={{ m: 4, textAlign: 'center' }}>
                    Blossoming Reviews
                    <br />
                    {/* https://mui.com/material-ui/react-stepper/#text-with-carousel-effect */}
                </Typography>
                <Typography sx={{ textAlign: 'center' ,mb: 4}}>
                    Coming Soon!
                    <br />
                </Typography>
            </Box>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ backgroundColor: theme.palette.secondary.main, height: { xs: 'auto', md: '60vh' }, p: 2, border: 'none' }}
                    >
                        <Typography variant="h3" sx={{ m: 4, textAlign: { xs: 'center', md: 'left' } }} color='common.white'>
                            Ready to Transform Your Business with Heart-Centered Software Solutions?
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ backgroundColor: theme.palette.custom.tertiary, height: { xs: 'auto', md: '60vh' }, p: 2, border: 'none' }}
                    >
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            If so, your journey
                        </Typography>
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            starts here.
                        </Typography>
                        <Button variant="contained" color="primary" sx={{ mt: 4, alignSelf: 'center', fontFamily: theme.typography.fontFamily }}>
                            Let's get started together
                        </Button>
                    </Box>
                </Grid>
            </Grid>


            <BottomNav />
        </>
    );
};

export default Home;